import { RootState, BankStatementsState } from "@/store/types/";
import { ActionContext, Commit } from "vuex";
import { apiAxios } from "@/store/apiAxios";
import { Bank } from "@/types/";

const state = {
  banks: [],
  fetched: false,
  consent: false,
  userConsent: false,
  emptyBankstatement: false,
};

const mutations = {
  setFetched(state: BankStatementsState, fetched: boolean): void {
    state.fetched = fetched;
  },
  setBanks(state: BankStatementsState, banks: Bank[]): void {
    state.banks = banks;
    state.fetched = true;
  },
  setConsent(state: BankStatementsState, consent: boolean): void {
    state.consent = consent;
  },

  setUserConsent(state: BankStatementsState, userConsent: boolean): void {
    state.userConsent = userConsent;
  },
  setEmptyBankstatement(
    state: BankStatementsState,
    emptyBankstatement: boolean
  ): void {
    state.emptyBankstatement = emptyBankstatement;
  },
};
const getters = {
  getBanks(): Bank[] {
    return state.banks;
  },

  getUserConsent(): boolean {
    return state.userConsent;
  },

  getEmptyBankstatement(): boolean {
    return state.emptyBankstatement;
  },
};

const actions = {
  fetchBanks({ commit }: { commit: Commit }): any {
    if (state.fetched === true) return;
    return apiAxios
      .get("/api/bankstatement/banks")
      .then((response) => commit("setBanks", response.data));
  },
  async fetchConsentLink(
    _: ActionContext<BankStatementsState, RootState>,
    bankId: string
  ): Promise<any> {
    return await apiAxios.get(`/api/bankstatement/consentLink/${bankId}`);
  },

  async fetchUserConsent(
    { commit }: { commit: Commit },
    userId: string
  ): Promise<any> {
    const response = await apiAxios.get(`/api/bankstatement/${userId}`);

    if (Object.keys(response.data).length == 0) {
      commit("setUserConsent", false);
      return false;
    }

    commit("setUserConsent", true);
    return response.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
